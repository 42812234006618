// @ts-nocheck
/* eslint-disable no-irregular-whitespace */
/* eslint-disable react/no-unknown-property */
/* eslint-disable prefer-template */
/* eslint-disable react/self-closing-comp */
/* eslint-disable import/no-unresolved */
/* eslint-disable padding-line-between-statements, import/order, import/newline-after-import, spaced-comment */
/* eslint-disable @typescript-eslint/no-unused-vars, react/jsx-newline, react/jsx-no-bind, no-else-return */
/* eslint-disable react-hooks/rules-of-hooks, react-hooks/exhaustive-deps, @typescript-eslint/no-use-before-define, no-lonely-if, no-nested-ternary, import/no-extraneous-dependencies */
/* eslint-disable object-shorthand */
/* eslint-disable react/jsx-newline */
/* eslint-disable react/button-has-type */
/* eslint-disable no-unneeded-ternary */
/* eslint-disable react/jsx-curly-brace-presence */
/* eslint-disable no-nested-ternary */


import React, { useState, useEffect } from 'react';
import axios from 'axios';
import {
  Box,
  Button,
  Container,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Typography,
  CircularProgress,
  Alert,
} from '@mui/material';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css'; // Import Toastify styles
import { useIntl } from 'react-intl';
import { useHistory } from 'react-router-dom';

const AddMerchant = () => {
  const [merchantName, setMerchantName] = useState('');
  const [merchantEnName, setMerchantEnName] = useState('');
  const [merchantStatus, setMerchantStatus] = useState(1); // Default selected status
  const [statuses, setStatuses] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(null);
  const currentLanguageCode = localStorage.getItem("EASY_MOBILITY_LANGUAGE") || 'en-us' //	ar-eg
  const intl = useIntl();
  const history = useHistory();
    let userPersonalData: any = '';
    let userAuthData :any= '';
    const authData = localStorage.getItem("authData");
     
    if (authData !== null) {
     const parsedAuthData = JSON.parse(authData);
     userPersonalData = parsedAuthData.userData;
     userAuthData = parsedAuthData.userToken;
    }
  // Get available merchant statuses on component mount
  useEffect(() => {
    const fetchStatuses = async () => {
      setIsLoading(true);
      setError(null); // Clear previous errors
      try {
        let url = `${process.env.REACT_APP_ABT_ALL_APIS_BASE_URL}/api/v1/merchants/getStatus`;
        const response = await axios.get(url);
        setStatuses(response.data.data);
      } catch (error) {
        setError(`Failed to get Status [${error}]`);
        toast.error(`Failed to get Status [${error}]`, {
            position: 'top-right',
            autoClose: 5000,
            hideProgressBar: true,
            closeOnClick: true,
          });
      } finally {
        setIsLoading(false);
      }
    };

    fetchStatuses();
  }, []);

  const handleSubmit = async (event) => {
    event.preventDefault();
    setIsLoading(true);
    setError(null); // Clear previous errors
    try {
      let url = `${process.env.REACT_APP_ABT_ALL_APIS_BASE_URL}/api/v1/merchants/addNewMerchant`;
      const response = await axios.post(url, {
        MERCHANT_STATUS: merchantStatus,
        MERCHANT_NAME: merchantName,
        MERCHANT_EN_NAME: merchantEnName,
      }, {
        headers: {
          'access-token': userAuthData?.access_token,
          'uid': userAuthData?.uid,
          'client': userAuthData?.client,
        },
      });
      toast.success('Merchant added successfully!', {
        position: 'top-right',
        autoClose: 5000,
        hideProgressBar: true,
        closeOnClick: true,
        toastStyle: {
          backgroundColor: '#F1662D',
          color: 'white',
        },
      });
      
      setTimeout(() => {
        history.push(`/CardsManagement/merchants/`);
      }, 500); // Redirect after 2 seconds
      // Clear form fields
      setMerchantName('');
      setMerchantEnName('');
      setMerchantStatus(1); // Reset to default status
    } catch (error) {
     
      if (error && error.response && error.response.data && error.response.data.message ){
        setError(error.response.data.message);
        toast.error(`Failed to add merchant [${error.response.data.message}]`, {
            position: 'top-right',
            autoClose: 5000,
            hideProgressBar: true,
            closeOnClick: true,
          });
       }else{
        setError(`Failed to add merchant [${error}]`);
        toast.error(`Failed to add merchant [${error}]`, {
            position: 'top-right',
            autoClose: 5000,
            hideProgressBar: true,
            closeOnClick: true,
          });
       }
     
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <>
     <Box sx={{  marginTop: -5, padding: 4 }}>
     <Typography sx={{ color: '#252733',fontWeight:"bold",marginBottom:"16px" }}>   { intl.formatMessage({ id: 'AddNewMerchant' })}
        </Typography>
       
        <Box component="form" onSubmit={handleSubmit} >
        <label className='labelStyle'>{intl.formatMessage({ id: 'MerchantNameAr' })}</label>
            <input type="text"         
            className='inputStyle'
            id="merchantName"
      
            value={merchantName}
            onChange={(e) => setMerchantName(e.target.value)}
            required
            
          />
              <label className='labelStyle'>{intl.formatMessage({ id: 'MerchantNameEn' })}</label>
            <input type="text"         
            className='inputStyle'
            id="merchantEnName"
     
            value={merchantEnName}
            onChange={(e) => setMerchantEnName(e.target.value)}
            required
           
          />
          <FormControl >
          <label  className='labelStyle' >{intl.formatMessage({ id: 'MerchantStatus' })}</label>
          {isLoading ? (
                
                  <CircularProgress size={24} style={{ display:"block" }} />
                
              ) : (  <select
              className='inputStyle'
              id="merchantStatus"
              value={merchantStatus}
              label={intl.formatMessage({ id: 'MerchantStatus' })}
              onChange={(e) => setMerchantStatus(e.target.value)}
              required
            >
             
                {statuses.map((status) => (
                  <option key={status.status_id} value={status.status_id}>
                    {currentLanguageCode == "en-us"?status.status_en_name :status.status}
                  </option>
                ))}
             
            </select>  )}
          </FormControl>
          <div sx={{ marginBottom: 2 ,display:"block"}}>
              <button
                type="submit"
                className='updateBtnStyle'
                
                disabled={isLoading}
              
              >
                {isLoading ?intl.formatMessage({ id: 'Adding' }) : intl.formatMessage({ id: 'AddMerchant' })}
              </button>
              <button
              
              className='cancelBtnStyle'
              
                onClick={() => history.push(`/CardsManagement/merchants/`)}
              >
                {intl.formatMessage({ id: 'Back' })}
              </button>
         </div>
        </Box>
      </Box>
      <ToastContainer />
    </>
  );
};

export default AddMerchant;
