// @ts-nocheck
/* eslint-disable radix */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable no-irregular-whitespace */
/* eslint-disable react/no-unknown-property */
/* eslint-disable prefer-template */
/* eslint-disable react/self-closing-comp */
/* eslint-disable import/no-unresolved */
/* eslint-disable padding-line-between-statements, import/order, import/newline-after-import, spaced-comment */
/* eslint-disable @typescript-eslint/no-unused-vars, react/jsx-newline, react/jsx-no-bind, no-else-return */
/* eslint-disable react-hooks/rules-of-hooks, react-hooks/exhaustive-deps, @typescript-eslint/no-use-before-define, no-lonely-if, no-nested-ternary, import/no-extraneous-dependencies */
/* eslint-disable object-shorthand */
/* eslint-disable react/jsx-newline */
/* eslint-disable react/button-has-type */
/* eslint-disable no-unneeded-ternary */
/* eslint-disable react/jsx-curly-brace-presence */
/* eslint-disable no-nested-ternary */
// eslint-disable-next-line radix
// eslint-disable-next-line jsx-a11y/click-events-have-key-events
import React, { useState } from 'react';
import {
  Button,
  Fade,
  Popper,
  makeStyles,
  createStyles,
  ClickAwayListener,
  Paper,
  TextField,
} from '@material-ui/core';
import { format } from 'date-fns';
import { useIntl } from 'react-intl';

import CloseIcon from './close-icon.svg';
import FilterIcon from './filter_alt_black.svg';
import lightFilterIcon from './filter_alt_white.svg';

const useStyles = makeStyles(() =>
  createStyles({
    multiActionButton: {
      height: 40,
      padding: '4px 18px',
      borderRadius: "30px",
      borderColor: '#DFE4EA',
      textTransform: "capitalize",
      fontSize: "13px",
      '&:hover': {
        opacity: 1,
      },
    },
    paperStyle: {
      width: 364,
      backgroundColor: '#F4F6FB',
      padding: '20px',
      paddingTop: '25px',
      paddingBottom: '25px',
      borderRadius: 15,
      boxShadow: '0px 2px 10px 0px rgba(0, 0, 0, 0.2)',
    },
    popperStyle: {
      marginTop: 6,
      zIndex: 100000,
    },
    closeFilters: {
      marginRight: 10,
      cursor: 'pointer',
      '& :hover': {
        opacity: 0.7,
      },
    },
    filterField: {
      marginBottom: 10,
      width: '100%',
      backgroundColor: "white"
    },
  })
);

const FilterChargingTransaction: React.FC<{
  applyFilters: (filters: Record<string, string>) => void;
}> = ({ applyFilters }) => {
  const classes = useStyles();
  const intl = useIntl();
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [open, setOpen] = useState<boolean>(false);
  const [filters, setFilters] = useState<Record<string, string>>({
    startDate: '',
    endDate: '',
    uid: '',
    cardSerialNumber: '',
    merchantPosUserName: '',
    merchantNameOrEnName: '',
  });
  const [filtersApplied, setFiltersApplied] = useState<boolean>(false);

  const handleFilterClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
    setOpen(prevOpen => !prevOpen);
  };

  const handleFilterClose = () => {
    setOpen(false);
  };

  const handleFilterChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    setFilters((prevFilters) => ({
      ...prevFilters,
      [name]: value,
    }));
  };

  const handleApplyFilters = () => {
    const formattedFilters = {
      ...filters,
      startDate: filters.startDate ? format(new Date(filters.startDate), "MM/dd/yyyy, hh:mm:ss a") : '',
      endDate: filters.endDate ? format(new Date(filters.endDate), "MM/dd/yyyy, hh:mm:ss a") : ''
    };
    console.log(formattedFilters);
    applyFilters(formattedFilters);
    setFiltersApplied(true);
    handleFilterClose();
  };

  const handleClearFilters = () => {
    setFilters({
      startDate: '',
      endDate: '',
      uid: '',
      cardSerialNumber: '',
      merchantPosUserName: '',
      merchantNameOrEnName: '',
    });
    applyFilters({});
    setFiltersApplied(false);
    handleFilterClose();
  };

  return (
    <div>
      <Button
        variant="outlined"
        color="default"
        className={classes.multiActionButton}
        onClick={handleFilterClick}
        style={{
          marginTop: "-8px", marginLeft: "16px", marginRight: "16px",
          backgroundColor: Object.keys(filters).some(key => filters[key]) ? '#4b506D' : '#fff',
          color: Object.keys(filters).some(key => filters[key]) ? '#fff' : '#4b506D',
        }}
      >
        <img
          src={Object.keys(filters).some(key => filters[key]) ? lightFilterIcon : FilterIcon}
          alt="filter transactions"
        />
        {intl.formatMessage({ id: 'filter' })}
      </Button>

      <Popper
        open={open}
        anchorEl={anchorEl}
        transition
        className={classes.popperStyle}
      >
        {({ TransitionProps }) => (
          <ClickAwayListener onClickAway={handleFilterClose}>
            <Fade {...TransitionProps} timeout={350}>
              <Paper className={classes.paperStyle}>
                <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                  <span style={{ color: '#444', fontSize: 15, fontWeight: 500 }}>
                    {intl.formatMessage({ id: 'filters' }).toUpperCase()}
                  </span>
                  <span
                    onClick={handleFilterClose}
                    role="button"
                    tabIndex={0}
                    className={classes.closeFilters}
                  >
                    <img src={CloseIcon} alt="close filters" />
                  </span>
                </div>

                {/* Search Inputs */}
                <TextField
                  style={{ marginTop: "15px" }}
                  className={classes.filterField}
                  variant="outlined"
                  margin="dense"
                  size="small"
                  label={intl.formatMessage({ id: 'uid' })}
                  name="uid"
                  value={filters.uid}
                  onChange={handleFilterChange}
                />
                <TextField
                  className={classes.filterField}
                  variant="outlined"
                  margin="dense"
                  size="small"
                  label={intl.formatMessage({ id: 'FareMediaSerial' })}
                  name="cardSerialNumber"
                  value={filters.cardSerialNumber}
                  onChange={handleFilterChange}
                />
                <TextField
                  className={classes.filterField}
                  variant="outlined"
                  margin="dense"
                  size="small"
                  label={intl.formatMessage({ id: 'POSName' })}
                  name="merchantPosUserName"
                  value={filters.merchantPosUserName}
                  onChange={handleFilterChange}
                />
                <TextField
                  className={classes.filterField}
                  variant="outlined"
                  margin="dense"
                  size="small"
                  label={intl.formatMessage({ id: 'MerchantNameAr' })}
                  name="merchantNameOrEnName"
                  value={filters.merchantNameOrEnName}
                  onChange={handleFilterChange}
                />
                <TextField
                  className={classes.filterField}
                  variant="outlined"
                  margin="dense"
                  size="small"
                  label={intl.formatMessage({ id: 'StartDate' })}
                  name="startDate"
                  type="date"
                  value={filters.startDate}
                  onChange={handleFilterChange}
                  InputLabelProps={{ shrink: true }}
                />
                <TextField
                  className={classes.filterField}
                  variant="outlined"
                  margin="dense"
                  size="small"
                  label={intl.formatMessage({ id: 'EndDate' })}
                  name="endDate"
                  type="date"
                  value={filters.endDate}
                  onChange={handleFilterChange}
                  InputLabelProps={{ shrink: true }}
                />
                {/* Action Buttons */}
                <div style={{ display: 'flex', justifyContent: 'space-between', marginTop: 15 }}>
                  <Button
                    variant="contained"
                    color="secondary"
                    className={classes.multiActionButton}
                    onClick={handleApplyFilters}
                  >
                    {intl.formatMessage({ id: 'apply' })}
                  </Button>
                  {filtersApplied && ( // Show reset button only if filters are applied
                    <Button
                      variant="text"
                      color="secondary"
                      onClick={handleClearFilters}
                    >
                      {intl.formatMessage({ id: 'reset' })}
                    </Button>
                  )}
                </div>
              </Paper>
            </Fade>
          </ClickAwayListener>
        )}
      </Popper>
    </div>
  );
};

export { FilterChargingTransaction };
