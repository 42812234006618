// @ts-nocheck
/* eslint-disable padding-line-between-statements, import/order, import/newline-after-import, spaced-comment */
/* eslint-disable @typescript-eslint/no-unused-vars, react/jsx-newline, react/jsx-no-bind, no-else-return */
/* eslint-disable react-hooks/rules-of-hooks, react-hooks/exhaustive-deps, @typescript-eslint/no-use-before-define, no-lonely-if, no-nested-ternary, import/no-extraneous-dependencies */
/* eslint-disable object-shorthand */
/* eslint-disable react/jsx-newline */
/* eslint-disable react/button-has-type */
/* eslint-disable no-unneeded-ternary */
/* eslint-disable react/jsx-curly-brace-presence */
/* eslint-disable no-nested-ternary */
import { useDispatch } from 'react-redux';
import { Link, useHistory, useRouteMatch } from 'react-router-dom';
  import React, { Fragment, useEffect, useRef, useState } from 'react'
  import { Suspense } from 'react'
  //import NavBar from '../components/NavBar';
  import { ToastContainer, toast } from 'react-toastify';
  import { useTranslation } from 'react-i18next'
  import "./style.css";
  //import Sidebar from '../components/Sidebar';
import { trimTrailingSlash, useTypedSelector } from 'helpers';
import { hasWriteAccessToMasterConfigSubSystem } from 'selectors';
  import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
  import KeyboardArrowLeftIcon from '@mui/icons-material/KeyboardArrowLeft';
  import AssistantDirectionIcon from '@mui/icons-material/AssistantDirection';
  import SearchIcon from '@mui/icons-material/Search';
  import cookies from 'js-cookie'
  import FilterAltIcon from '@mui/icons-material/FilterAlt';
  import CloseIcon from '@mui/icons-material/Close';
  import Loading from "./images/loading2.svg";
  import {
    Button,
    Popover,
    ThemeProvider,
    Typography,
    createTheme,
  } from '@material-ui/core';
  import {
    createStyles,
    Grid,
    makeStyles,
    Table,
    TableBody,
    TableCell,
    TableHead,
    TableRow,

  } from '@material-ui/core';
  import { basicButtonStyles, tableRowStyles } from 'app/shared/styles';
  import { TextCell } from 'app/components/shared/table';

import { default as ReactSelect } from "react-select";

import { components } from "react-select";
import { Box } from '@mui/material';
import { useIntl } from 'react-intl';
import { useGetFilterOrganizations } from 'hooks';
import { useGetFilterGroups } from 'hooks/useGetFilterGroups';
import { useSelector } from 'react-redux';
import { log } from 'console';
import axios from 'axios';
import { CardTitle } from 'app/components/shared/card-title';
import DatePicker from "react-datepicker";
import { format } from 'date-fns';
import PaidIcon from '@mui/icons-material/Paid';
import "react-datepicker/dist/react-datepicker.css";
import { Card, Col, Container, Row } from 'react-bootstrap';
import EventNoteIcon from '@mui/icons-material/EventNote';
import EditCalendarIcon from '@mui/icons-material/EditCalendar';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import DisabledByDefaultIcon from '@mui/icons-material/DisabledByDefault';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import { TicketsTranscTable } from './components/ticketsTranscTable';
import { ChargingTranscTable } from './components/chargingTranscTable';
import { TableAnalysis } from './components/aboveTableFunctions/tableAnalysis';
import { TableControlDataView } from './components/aboveTableFunctions/tableControlDataView';
import { TableFilter } from './components/aboveTableFunctions/tableFilter';
import { TransactionTabs } from './components/transactionTabs';
import { FaraMediaInfoContainer } from './components/faraMediaInfoContainer';
import { SearchSection } from './components/searchSection';

import ContentPasteSearchIcon from '@mui/icons-material/ContentPasteSearch';
import HandleMessage from './uitily/handleMessages';
import { storeFareMediaInfo } from 'actions/customer-support.actions';
const Option= (props) => {
  return (
    <div >
      <components.Option {...props}>
        <input
          type="checkbox"
          checked={props.isSelected}
          onChange={() => null}
        />{" "}
        <label className='lab-fil'>{props.label} </label>
      </components.Option>
    </div>
  );
};
const useStyles = makeStyles(() =>
createStyles({
  table: { minWidth: 750, overflow: 'scroll', whiteSpace: 'nowrap' },
  addButton: { ...basicButtonStyles },
  row: { ...tableRowStyles },
})
);

const CustomerSupportHome: React.FC = () => {
      const match = useRouteMatch();
      const baseURL = trimTrailingSlash(match.url);
      const intl = useIntl();
      const classes = useStyles();
      const organizationsGlobal = useGetFilterOrganizations({ entity: 'Routes' });
      const groupsGlobal = useGetFilterGroups({ entity: 'Routes' });
      const [optionSelectedOrg, setOptionSelectedOrg] = useState<any[]>([]);
      const [optionSelectedGro, setOptionSelectedGro] = useState<any[]>([]);
      const [orgData, setOrgData] = useState<any[]>([]);
      const [groData, setGroData] = useState<any[]>([]);
      const [isLoading, setIsLoading] = useState(false); // Loading state
      const history = useHistory();
      const currentLanguageCode = localStorage.getItem("EASY_MOBILITY_LANGUAGE") || 'en-us' //	ar-eg
       //req
       const [page, setPage] = useState<any>(1);
       const [limit, setLimit] = useState<any>(10);
       const [inputSearchValue, setInputSearchValue] = useState<any>("");
       const [isFilterClicked, setIsFilterClicked] = useState<any>(0);
       //res
       const [transactions,setTransactions] =  useState<any[]>([]);
       const [pageCount, setPageCount] = useState<any>(0);
       const [transactionsTotalAmount, setTransactionsTotalAmount] = useState(null); 
       const [transactionsNumber, setTransactionsNumber] = useState(null); 
       const [isMakeSearch, setIsMakeSearch] = useState(false); 
       //datetime handle 
      const [startDate, setStartDate] = useState(new Date());
      const [endDate, setEndDate] = useState(new Date());
      const [filterStartDateValue, setFilterStartDateValue] = useState(null); 
      const [filterEndDateValue, setFilterEndDateValue] = useState(null); 
     // const [fareMediaInfo, setFareMediaInfo] = useState(null);
      const [transactionsBaseUrl, setTransactionsBaseUrl] = useState(`${process.env.REACT_APP_ABT_ALL_APIS_BASE_URL}/api/v1/transactions/get_pay_ticket_transactions_for_one_fare_media`);
      const dispatch= useDispatch();
      const fareMediaInfo = useSelector (state => state.customerSupport.fareMediaInfo);
      //auth
      let authData = null
      let userData = null
      let userToken =null
  
try{
       
       authData =useSelector (state => state.authentication?.user);
        //authData = JSON.parse(localStorage.getItem("authData"));
        userData = authData.userData
        userToken = authData.userToken
}catch(e){
    HandleMessage(`Sorry , ${"Auth Failed"}  #11269645`,"error")
}
       const [tabsValue, setTabsValue] = React.useState(0);

  const handleChangeTabs = (event: React.SyntheticEvent, newValue: number) => {
 // 0 -> tickets
 // 1 -> charging
 if(newValue ==0){
  setTransactionsBaseUrl(`${process.env.REACT_APP_ABT_ALL_APIS_BASE_URL}/api/v1/transactions/get_pay_ticket_transactions_for_one_fare_media`) 
 }else{
  setTransactionsBaseUrl(`${process.env.REACT_APP_ABT_ALL_APIS_BASE_URL}/api/v1/transactions/get_charging_transactions_for_one_fare_media`)
 }
    setTabsValue(newValue);

    setPage(1);
    setIsFilterClicked(0);
    if(inputSearchValue.trim().length>0 && inputSearchValue.trim().length <= 45) { 
      
      setIsMakeSearch(true)
      getFareMediaInfo()
    }
    setTransactions([])
    setPageCount(0)
    setIsLoading(false); 
    setLimit(10)
    setTransactionsNumber(null)    
    setTransactionsTotalAmount(null)
    setFilterStartDateValue(null)
    setFilterEndDateValue(null)
  };
    useEffect(()=>{
        dispatch(storeFareMediaInfo(null));
    },[])
      useEffect(()=>{
 
      const handleAPIRequest =  () => {
          let url=(`${transactionsBaseUrl}?page=${page}&pageSize=${limit}`)
          setIsLoading(true)
   axios.post(url,
    {
     fare_media_serial:inputSearchValue,
    startDate:filterStartDateValue,
      endDate:filterEndDateValue
    },
    { 
      headers:
      {
      'access-token' : userToken['access-token'],
      'uid' :userToken.uid ,
      'client': userToken.client 
    }
  }
  )
   .then((res)=> {
          setTransactions(res.data.data.result)
          setPageCount(res.data.data.totalPages)
          setIsLoading(false);
          setIsMakeSearch(false)
          setTransactionsNumber(res.data.data.totalCount)    
          setTransactionsTotalAmount(res.data.data.totalAmount)  
      })
      .catch((error)=>{

          if (error && error.response && error.response.data && error.response.data.message ){
            HandleMessage(`Sorry , ${error.response.data.message}  #9675`,"error")
           }else{
            HandleMessage(`Sorry , ${error}  #36698552`,"error")
           }
          setTransactions([])
          setTransactionsNumber(null)    
          setTransactionsTotalAmount(null)  
          setIsLoading(false); 
          setIsMakeSearch(false)   
         /* setFilterStartDateValue(null)
          setFilterEndDateValue(null)*/
      })
  } 
            if(isMakeSearch ){
              handleAPIRequest();
            }  
        },[page,limit,isMakeSearch,inputSearchValue,filterStartDateValue,filterEndDateValue,transactionsBaseUrl])
        function handlePrevious() {
          setIsMakeSearch(true)
          setPage((p) => {
            if (p === 1) return p;
            return p - 1;
          });
        }
        function handleNext() {
          setIsMakeSearch(true)
          setPage((p) => {
            if (p === pageCount) return p;
            return p + 1;
          });
        }
        function handleChangeLimit(lim) {
          setIsMakeSearch(true)
          setLimit(lim);
          setPage(1)
        }
   const clickSearchBtn = async (e: React.FormEvent) => {
    e.preventDefault();
    if(inputSearchValue.trim().length>0){
        if(inputSearchValue.trim().length >45){
            HandleMessage(`Sorry , ${"Value Is Very Long"}  #1552111`,"error")
            setPage(1);
            setIsFilterClicked(0);
            setIsMakeSearch(false)
            setTransactions([])
            setPageCount(0)
            setIsLoading(false); 
            setLimit(10)
            setTransactionsNumber(null)    
            setTransactionsTotalAmount(null)
            setFilterStartDateValue(null)
            setFilterEndDateValue(null)
            //setFareMediaInfo({})
            dispatch(storeFareMediaInfo({}));
        }else{
      
          getFareMediaInfo()  

          setIsFilterClicked(0);
            setTransactions([])
          setPageCount(0)
          setIsLoading(false); 
          setLimit(10)
          setTransactionsNumber(null)    
          setTransactionsTotalAmount(null)
          setFilterStartDateValue(null)
          setFilterEndDateValue(null)
         
        }
 }else{
  //setFareMediaInfo(null)
  dispatch(storeFareMediaInfo(null));
  setPage(1);
  setIsFilterClicked(0);
  setIsMakeSearch(false)
  setTransactions([])
  setPageCount(0)
  setIsLoading(false); 
  setLimit(10)
  setTransactionsNumber(null)    
  setTransactionsTotalAmount(null)
  setFilterStartDateValue(null)
  setFilterEndDateValue(null)
 }}

 const getFareMediaInfo = async ()=>{
  const handleAPIRequest =  () => {
    let url=(`${process.env.REACT_APP_ABT_ALL_APIS_BASE_URL}/api/v1/fareMedia/getOneFareMedia`)
    setIsLoading(true)
axios.post(url,
{

fare_media_serial:inputSearchValue

},
{ 
headers:
{
'access-token' : userToken['access-token'],
'uid' :userToken.uid ,
'client': userToken.client 
}
}
)
.then((res)=> {
  if(res.data.data){
    //setFareMediaInfo(res.data.data)
    console.log(res.data.data);
    
    dispatch(storeFareMediaInfo(res.data.data));

    setPage(1)
    setIsMakeSearch(true)
  }else{
    //setFareMediaInfo({})
    setIsMakeSearch(false)
    dispatch(storeFareMediaInfo({}));
  }
    setIsLoading(false); 
})
.catch((err)=>{
  if(err.response && err.response.data && err.response.data.message  == "No Fare Media With This Serial"){
    console.log(err.response.data.message);
  }else{
    if (err && err.response && err.response.data && err.response.data.message ){
      HandleMessage(`Sorry , ${err.response.data.message}  #967511`,"error")
     }else{
      HandleMessage(`Sorry , ${err}  #36698552111`,"error")
     }
  }
  setIsMakeSearch(false)
    //setFareMediaInfo({})
    dispatch(storeFareMediaInfo({}));
    setIsLoading(false); 
})
} 
handleAPIRequest()
 }

  let clearAll = async () => {
        setInputSearchValue("");
        //setFareMediaInfo(null)
        dispatch(storeFareMediaInfo(null));
        setPage(1);
        setIsFilterClicked(0);
        setIsMakeSearch(false)
        setTransactions([])
        setPageCount(0)
        setIsLoading(false); 
        setLimit(10)
        setTransactionsNumber(null)    
        setTransactionsTotalAmount(null)
        setFilterStartDateValue(null)
        setFilterEndDateValue(null)
 }
 function convertToLocalTime(utcDateString) {
  const date = new Date(utcDateString);
  console.log(date);
  
  return date.toLocaleString(); // Converts to local time and formats it
}
function convertToFormattedUTC(utcDateString) {
  const date = new Date(utcDateString);
  const options = { timeZone: 'UTC' };
  return date.toLocaleString('en-US', options); // Formats the UTC date
}

  const runDateTimeFilter = (e) => {
    e.preventDefault();
    const formattedStartDate = format(startDate, 'MM/dd/yyyy, hh:mm:ss a');
    const formattedEndDate = format(endDate, 'MM/dd/yyyy, hh:mm:ss a');
   
    setIsMakeSearch(true)
    setFilterStartDateValue(formattedStartDate)
    setFilterEndDateValue(formattedEndDate)
    setPage(1)
    setIsFilterClicked(1);
    handleClose();
  };
  const clearFilterResultOnly = () => {
    setPage(1);
    setIsFilterClicked(0);
    if(inputSearchValue.trim().length>0 && inputSearchValue.trim().length <= 45) {
      setIsMakeSearch(true)
      getFareMediaInfo()
    }
    setFilterStartDateValue(null)
    setFilterEndDateValue(null)
    setStartDate(new Date())
    setEndDate(new Date())
  };

 //handle filteration part
    const [anchorEl, setAnchorEl] = React.useState(null);
    const filterPopoverClickButton = (event) => {
      setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
      setAnchorEl(null);
    };
    const open = Boolean(anchorEl);
    const idOfPopUp = open ? 'simple-popover' : undefined;
//to handle write permissions
  const hasCustomerSupportWriteAccess = useTypedSelector(state =>
    hasWriteAccessToMasterConfigSubSystem(state, 'CustomerSupport')
  );
  if(authData && userData && userToken){
return( 
<div style={{flex:"1 1 auto", display:"flex", flexFlow:"column"}}>
<h2 className='page-header'>
            <ContentPasteSearchIcon style={{margin:"4px",fontSize:"25px"}}/>
          {intl.formatMessage({ id: 'fareMediaInquery' })}</h2>

<SearchSection clickSearchBtn={clickSearchBtn} setInputSearchValue={setInputSearchValue}
inputSearchValue={inputSearchValue} transactions={transactions} clearAll={clearAll} isLoading={isLoading}/>

{ fareMediaInfo  ? fareMediaInfo.id ?
<FaraMediaInfoContainer fareMediaInfo={fareMediaInfo} convertToLocalTime={convertToLocalTime}/>
:<div className='no-fare-media-design'>{intl.formatMessage({ id: 'NoFareMediaExist'})}</div>
:<></>
}
{ fareMediaInfo && fareMediaInfo.id ?(
<>
 <TransactionTabs tabsValue={tabsValue} handleChangeTabs={handleChangeTabs}/>
<div className='table-features-section'>

{transactions?.length>0  ?<TableAnalysis  transactionsNumber={transactionsNumber} transactionsTotalAmount={transactionsTotalAmount}/>: <></>}

{transactions?.length>0  ?  <TableControlDataView
   page={page} pageCount={pageCount} 
   limit={limit} handlePrevious={handlePrevious}
   handleNext={handleNext} handleChangeLimit={handleChangeLimit}/>: <></>}

                            <TableFilter isFilterClicked ={isFilterClicked} filterStartDateValue={filterStartDateValue}
                            clearFilterResultOnly={clearFilterResultOnly} transactions={transactions} 
                            filterPopoverClickButton={filterPopoverClickButton} idOfPopUp={idOfPopUp} 
                            open={open}  anchorEl={anchorEl}  
                            handleClose={handleClose} runDateTimeFilter={runDateTimeFilter} 
                            startDate={startDate} setStartDate={setStartDate}
                            endDate={endDate} setEndDate={setEndDate}/>

</div> 
<div className="parentTable-customer-support" >
{ tabsValue == 0 ?
<TicketsTranscTable  transactions={transactions} isLoading={isLoading} convertToLocalTime={convertToLocalTime} tabsValue={tabsValue}/>
:<ChargingTranscTable  transactions={transactions} isLoading={isLoading} convertToFormattedUTC={convertToFormattedUTC} tabsValue={tabsValue}/>
  }
</div> 
</>):<></>}
</div>)
  }else{
    return (
      <h2>
     <center> {intl.formatMessage({ id: 'LoginAgain' })} </center>
       </h2>
   )
  }
};

export { CustomerSupportHome };
