// @ts-nocheck
/* eslint-disable no-irregular-whitespace */
/* eslint-disable react/no-unknown-property */
/* eslint-disable prefer-template */
/* eslint-disable react/self-closing-comp */
/* eslint-disable import/no-unresolved */
/* eslint-disable padding-line-between-statements, import/order, import/newline-after-import, spaced-comment */
/* eslint-disable @typescript-eslint/no-unused-vars, react/jsx-newline, react/jsx-no-bind, no-else-return */
/* eslint-disable react-hooks/rules-of-hooks, react-hooks/exhaustive-deps, @typescript-eslint/no-use-before-define, no-lonely-if, no-nested-ternary, import/no-extraneous-dependencies */
/* eslint-disable object-shorthand */
/* eslint-disable react/jsx-newline */
/* eslint-disable react/button-has-type */
/* eslint-disable no-unneeded-ternary */
/* eslint-disable react/jsx-curly-brace-presence */
/* eslint-disable no-nested-ternary */
import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { useIntl } from 'react-intl';
import { useHistory } from 'react-router-dom';
import {
  Box,
  Button,
  Container,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Typography,
  CircularProgress,
  Alert,
} from '@mui/material';
const AddMerchantPOS = () => {
  const currentLanguageCode = localStorage.getItem('EASY_MOBILITY_LANGUAGE') || 'en-us';
  const intl = useIntl();
  const history = useHistory();
  const [merchants, setMerchants] = useState([]);
  const [merchantId, setMerchantId] = useState(null);

  const [statuses, setStatuses] = useState([]);
  const [username, setUsername] = useState('');
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [posSerialNumber, setPosSerialNumber] = useState('');
  const [samUID, setSamUID] = useState('');
  const [simMSIDN, setSimMSIDN] = useState('');
  const [userLimit, setUserLimit] = useState('');
  const [status, setStatus] = useState(1);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(null);
  const [isMerchantsDataLoading, setIsMerchantsDataLoading] = useState(false);
  const [isStatusLoading, setIsStatusLoading] = useState(false);
  let userPersonalData = '';
  let userAuthData = '';
  const authData = localStorage.getItem('authData');
  
  if (authData !== null) {
    const parsedAuthData = JSON.parse(authData);
    userPersonalData = parsedAuthData.userData;
    userAuthData = parsedAuthData.userToken;
  }

  const fetchStatuses = async () => {
    setIsStatusLoading(true);
    setError(null);
    try {
      const url = `${process.env.REACT_APP_ABT_ALL_APIS_BASE_URL}/api/v1/merchants/getStatus`;
      const response = await axios.get(url);
      setStatuses(response.data.data);
    } catch (error) {
      setError(`Failed to get Status [${error}]`);
      toast.error(`Failed to get Status [${error}]`, {
        position: 'top-right',
        autoClose: 5000,
        hideProgressBar: true,
        closeOnClick: true,
      });
    } finally {
      setIsStatusLoading(false);
    }
  };

  const fetchMerchants = async () => {
    setIsMerchantsDataLoading(true);
    setError(null);
    const url = `${process.env.REACT_APP_ABT_ALL_APIS_BASE_URL}/api/v1/merchants/getAllMerchants?status=1&page=1&pageSize=230`;
    try {
      const response = await axios.get(url, {
        headers: {
          'access-token': userAuthData?.access_token,
          uid: userAuthData?.uid,
          client: userAuthData?.client,
        },
      });
      setMerchants(response.data.data.result || []);
    } catch (error) {
      console.log('API ERROR', error);
      toast.error(`Sorry, ${error}. Please try again later or refresh the page. Code: #r1`, {
        position: 'top-center',
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
      });
    } finally {
      setIsMerchantsDataLoading(false);
    }
  };

  useEffect(() => {
    fetchStatuses();
    fetchMerchants();
  }, []);

  const handleSubmit = async (event) => {
    event.preventDefault();
console.log(merchantId);

    if (password !== confirmPassword) {
      setError('Passwords do not match');
      toast.error('Passwords do not match', {
        position: 'top-right',
        autoClose: 5000,
        hideProgressBar: true,
        closeOnClick: true,
      });
      return;
    }
if(userLimit<1){

setError(currentLanguageCode == 'en-us' ? `Limit should greater than 0` : ` الحد المسموح يجب ان يكون اكبر من 0`);
toast.error(currentLanguageCode == 'en-us' ? `Limit should greater than 0` : ` الحد المسموح يجب ان يكون اكبر من 0`, {
  position: 'top-right',
  autoClose: 5000,
  hideProgressBar: true,
  closeOnClick: true,
});
return;
}
    setIsLoading(true);
    setError(null);
    try {
      const url = `${process.env.REACT_APP_ABT_ALL_APIS_BASE_URL}/api/v1/merchantPOS/addNewMerchantPOS`;
      const response = await axios.post(
        url,
        {
          Email: email,
          Pass: password,
          pos_Serial_number: posSerialNumber,
          sam_UID: samUID,
          sim_MSIDN: simMSIDN,
          merchent_ID: merchantId,
          USER_NAME: username,
          USER_LIMIT: userLimit,
          STATUS: status,
        },
        {
          headers: {
            'access-token': userAuthData?.access_token,
            uid: userAuthData?.uid,
            client: userAuthData?.client,
          },
        }
      );
      toast.success('Merchant POS added successfully!', {
        position: 'top-right',
        autoClose: 5000,
        hideProgressBar: true,
        closeOnClick: true,
        toastStyle: {
          backgroundColor: '#F1662D',
          color: 'white',
        },
      });

      setTimeout(() => {
        history.push('/CardsManagement/merchantPOS/');
      }, 500);

      setEmail('');
      setPassword('');
      setConfirmPassword('');
      setPosSerialNumber('');
      setSamUID('');
      setSimMSIDN('');
      setMerchantId('');
      setUsername('');
      setUserLimit('');
      setStatus(1);
    } catch (error) {
      if (error && error.response && error.response.data && error.response.data.message) {
        if (String(error.response.data.message).includes("merchant_pos_user_name_key")) {
          setError(currentLanguageCode == 'en-us' ? `POS Name Is Already Exist` : `اسم الجهاز موجود من قبل`);
          toast.error(currentLanguageCode == 'en-us' ? `POS Name Is Already Exist` : `اسم الجهاز موجود من قبل`, {
            position: 'top-right',
            autoClose: 5000,
            hideProgressBar: true,
            closeOnClick: true,
          });
        } else if (String(error.response.data.message).includes("unique")) {
          setError(currentLanguageCode == 'en-us' ? `You Are Trying Enter Data already Exist` : `تم ادخال هذة البيانات من قبل`);
          toast.error(currentLanguageCode == 'en-us' ? `You Are Trying Enter Data already Exist` : `تم ادخال هذة البيانات من قبل`, {
            position: 'top-right',
            autoClose: 5000,
            hideProgressBar: true,
            closeOnClick: true,
          });
        } else {
          setError(error.response.data.message);
          toast.error(`Failed to add merchant POS [${error.response.data.message}]`, {
            position: 'top-right',
            autoClose: 5000,
            hideProgressBar: true,
            closeOnClick: true,
          });
        }
      } else {
        setError(`Failed to add merchant POS [${error}]`);
        toast.error(`Failed to add merchant POS [${error}]`, {
          position: 'top-right',
          autoClose: 5000,
          hideProgressBar: true,
          closeOnClick: true,
        });
      }
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <>
      <Box sx={{ marginTop: -5, padding: 4 }}>
        <Typography sx={{ color: '#252733', fontWeight: 'bold', marginBottom: '16px' }}>
          {intl.formatMessage({ id: 'AddNewMerchantPOS' })}
        </Typography>
    
        <Box component="form" onSubmit={handleSubmit}>
              <div className='parent-form'>
                   <div>
                            <label className='labelStyle'>{intl.formatMessage({ id: 'Name' })}</label>
                            <input
                              type="text"
                              className='inputStyle'
                              id="email"
                              value={email}
                              onChange={(e) => setEmail(e.target.value)}
                              required
                            />

                            <label className='labelStyle'>{intl.formatMessage({ id: 'POSName' })}</label>
                            <input
                              type="text"
                              className='inputStyle'
                              id="username"
                              value={username}
                              onChange={(e) => setUsername(e.target.value)}
                              required
                            />

                            <label className='labelStyle'>{intl.formatMessage({ id: 'Password' })}</label>
                            <input
                              type="password"
                              className='inputStyle'
                              id="password"
                              value={password}
                              onChange={(e) => setPassword(e.target.value)}
                              required
                            />

                            <label className='labelStyle'>{intl.formatMessage({ id: 'ConfirmPassword' })}</label>
                            <input
                              type="password"
                              className='inputStyle'
                              id="confirmPassword"
                              value={confirmPassword}
                              onChange={(e) => setConfirmPassword(e.target.value)}
                              required
                            />
                              
            <label className='labelStyle'>{intl.formatMessage({ id: 'SelectMerchant' })}</label>
            {isMerchantsDataLoading ? (
             
                  <CircularProgress size={24}  sx={{ display:"block" }}  />
            
              ) : ( <select
              className='inputStyle'
              id="merchantSelect"
              value={merchantId}
              onChange={(e) => setMerchantId(e.target.value)}
              required
           
            >
             
             <option value="" style={{fontWeight:"bold"}}>-----------</option>
               { merchants.map((merchant) => (
                  <option key={merchant.merchant_id} value={merchant.merchant_id}>
                      {currentLanguageCode == "en-us"?merchant.merchant_en_name :merchant.merchant_name}
                  </option>
                ))}
                
            </select>  )}
         
                  </div>
                  <div>
                            <label className='labelStyle'>{intl.formatMessage({ id: 'POS Serial Number' })}</label>
                            <input
                              type="text"
                              className='inputStyle'
                              id="posSerialNumber"
                              value={posSerialNumber}
                              onChange={(e) => setPosSerialNumber(e.target.value)}
                              required
                            />

                            <label className='labelStyle'>{intl.formatMessage({ id: 'SAM UID' })}</label>
                            <input
                              type="text"
                              className='inputStyle'
                              id="samUID"
                              value={samUID}
                              onChange={(e) => setSamUID(e.target.value)}
                              required
                            />

                            <label className='labelStyle'>{intl.formatMessage({ id: 'SIM MSIDN' })}</label>
                            <input
                              type="text"
                              className='inputStyle'
                              id="simMSIDN"
                              value={simMSIDN}
                              onChange={(e) => setSimMSIDN(e.target.value)}
                              required
                            />

                            <label className='labelStyle'>{intl.formatMessage({ id: 'UserLimit' })}</label>
                            <input
                              type="number"
                              className='inputStyle'
                              id="userLimit"
                              value={userLimit}
                              onChange={(e) => setUserLimit(e.target.value)}
                              required
                              min="1"
                            />
                       
                                          <label className='labelStyle'>{intl.formatMessage({ id: 'MerchantStatus' })}</label>
                                          {isStatusLoading ? (
                                           
                                              <CircularProgress size={24} sx={{ display:"block" }}/>
                                          
                                            ) : (      <select
                                            className='inputStyle'
                                            id="merchantStatus"
                                            value={status}
                                            onChange={(e) => setStatus(e.target.value)}
                                            required
                                          >
                                         
                                            {  statuses.map((status) => (
                                                <option key={status.status_id} value={status.status_id}>
                                                  {currentLanguageCode === 'en-us' ? status.status_en_name : status.status}
                                                </option>
                                              ))}
                                            
                                           </select>)}
                 
                  </div>
            </div>
       

   

          <div sx={{ marginBottom: 2, display: "block" }}>
            <button
              type="submit"
              className='updateBtnStyle'
              disabled={isLoading}
            >
              {isLoading ? intl.formatMessage({ id: 'Adding' }) : intl.formatMessage({ id: 'AddMerchantPOS' })}
            </button>
            <button
              className='cancelBtnStyle'
              onClick={() => history.push('/CardsManagement/merchantPOS/')}
            >
              {intl.formatMessage({ id: 'Back' })}
            </button>
          </div>
        </Box>
      </Box>
      <ToastContainer />
    </>
  );
};

export default AddMerchantPOS;

