import React, { useContext } from 'react';

import {
  Card,
  createStyles,
  Grid,
  makeStyles,
  Typography,
} from '@material-ui/core';
import { useIntl } from 'react-intl';
import { useHistory } from 'react-router-dom';

import { DIRECTIONS } from 'app/i18n-locale/locales-constants';
import { Header } from 'app/super-dash/home-header';
import { LanguageContext } from 'context/intl.context';
import { useTypedSelector } from 'helpers';
import {
  canReadAdminManagement,
  canReadFares,
  canReadFinancialControl,
  canReadMasterConfig,
  canReadOperations,
  canReadZoning,
  hasReadAccessToMasterConfigSubSystem,
  hasWriteAccessToMasterConfigSubSystem
} from 'selectors';

import AdminManagementIcon from './icons/admin-management.svg';
import ClearanceIcon from './icons/clearance.svg';
import ConfigIcon from './icons/config.svg';
import FaresIcon from './icons/fares.svg';
import HelpIcon from './icons/help.svg';
import OperationsIcon from './icons/operation.svg';
import SignageIcon from './icons/signage.svg';
import ZoneIcon from './icons/zones.svg';
import CustomerSupportIcon from './icons/customer-support.svg';
import CardsManagementIcon from './icons/cards-management.svg';
const useStyles = makeStyles(() =>
  createStyles({
    contentWrapper: { width: '77%', margin: '0 auto 20px auto' },
    welcomeContainer: {
      margin: '20px 18px',
      minHeight: 134,
      backgroundSize: 'cover',
      padding: '30px 60px',
      borderRadius: 40,
      background:
        'linear-gradient(135deg, rgba(238, 154, 229, 0.2) 0%, rgba(89, 97, 249, 0.2) 100%), linear-gradient(180deg, #030303 0%, #001252 100%)',
    },
    systemsWrapper: {
      display: 'flex',
      flexWrap: 'wrap',
    },
    gridItem: {
      padding: '10px 18px',
      textAlign: 'center',
    },
    card: {
      maxHeight: 153,
      cursor: 'pointer',
      border: 'none',
      background: '#fff',
      padding: 30,
      borderRadius: 35,
      '&:hover': { background: 'rgba(255, 255, 255, .7)' },
    },
    linkTitle: {
      fontFamily: 'Montserrat',
      fontSize: 15,
      fontWeight: 'normal',
      lineHeight: '18px',
      textTransform: 'uppercase',
      color: '#494A49',
    },
  })
);

const SystemLink: React.FC<{
  title: string;
  icon: string;
  path: string;
}> = props => {
  const classes = useStyles();
  const history = useHistory();
  const { title, icon, path } = props;
  const { direction } = useContext(LanguageContext);

  return (
    <Grid
      item
      className={classes.gridItem}
      onClick={() => history.push(path)}
      md={4}
      sm={6}
      xs={12}
    >
      <Card variant="outlined" className={classes.card}>
        <img src={icon} alt={title} style={{ marginBottom: 11, height: 65 }} />

        <Typography
          variant="body1"
          className={classes.linkTitle}
          style={{
            letterSpacing: direction === DIRECTIONS.RTL ? 0 : 2,
            fontSize: direction === DIRECTIONS.RTL ? 20 : 15,
          }}
        >
          {title}
        </Typography>
      </Card>
    </Grid>
  );
};

const SuperDash: React.FC = () => {
  const intl = useIntl();
  const classes = useStyles();

  const username = useTypedSelector(
    state => state.authentication?.user.userData.first_name
  );

  const canViewMasterConfig = useTypedSelector(state =>
    canReadMasterConfig(state)
  );

  const canViewAdminManagement = useTypedSelector(canReadAdminManagement);
  const canViewOperations = useTypedSelector(canReadOperations);
  const canViewFares = useTypedSelector(canReadFares);
  const canViewFinancialControl = useTypedSelector(canReadFinancialControl);
  const canViewZoning = useTypedSelector(canReadZoning);
  const canViewDigitalSignage = false;
  const canViewHelpDesk = false;
  const canViewCustomerSupport = useTypedSelector(state =>
    hasReadAccessToMasterConfigSubSystem(state, 'CustomerSupport')
  );
  //make CardsManagement can view mechanesm
   let canViewMerchants = useTypedSelector(state =>
    hasReadAccessToMasterConfigSubSystem(state, 'Merchants')
  );
  let canViewMerchantPOS = useTypedSelector(state =>
    hasReadAccessToMasterConfigSubSystem(state, 'MerchantPOS')
  );
  let canViewChargingTransactions = useTypedSelector(state =>
    hasReadAccessToMasterConfigSubSystem(state, 'ChargingTransactions')
  );
  const canViewCardsManagement = canViewMerchants || canViewMerchantPOS || canViewChargingTransactions ? true : false
  console.log("canViewCardsManagement " , canViewCardsManagement);
  
  const { direction } = useContext(LanguageContext);
const zoneDiv = {backgroundColor:'red',width:"27%"}
  return (
    <div dir={direction}>
      <Header />

      <div className={classes.contentWrapper}>
        <div className={classes.welcomeContainer}>
          <Typography
            variant="h4"
            style={{ color: '#fff', fontWeight: 'bold' }}
          >
            {`${intl.formatMessage({ id: 'welcome' })} ${username}`}

            {`${direction === DIRECTIONS.RTL ? '،' : ','}`}
          </Typography>

          <Typography
            variant="body1"
            style={{ color: '#f1f1f1', fontWeight: 400 }}
          >
            {intl.formatMessage({ id: 'selectStatement' })}
          </Typography>
        </div>

        <Grid container className={classes.systemsWrapper} dir={direction}>
          {canViewOperations && (
            <SystemLink
              title={intl.formatMessage({ id: 'operations' })}
              icon={OperationsIcon}
              path="/operations"
            />
          )}
          {canViewCardsManagement && (
            <SystemLink
              title={intl.formatMessage({ id: 'CardsManagement' })}
              icon={CardsManagementIcon}
              path="/CardsManagement"
            />
          )}
         
            {canViewCustomerSupport && (
            <SystemLink
              title={intl.formatMessage({ id: 'customerSupport' })}
              icon={CustomerSupportIcon}
              path="/customerSupport"
            />
          )}
          {canViewMasterConfig && (
            <SystemLink
              title={intl.formatMessage({ id: 'configuration' })}
              icon={ConfigIcon}
              path="/configuration"
            />
          )}

          {canViewAdminManagement && (
            <SystemLink
              title={intl.formatMessage({ id: 'adminManagement' })}
              icon={AdminManagementIcon}
              path="/adminManagement"
            />
          )}

          {canViewFinancialControl && (
            <SystemLink
              title={intl.formatMessage({ id: 'financialControl' })}
              icon={ClearanceIcon}
              path="/financialControl"
            />
          )}
 {canViewFares && (
            <SystemLink
              title={intl.formatMessage({ id: 'zoning' })}
              icon={ZoneIcon}
              path="/zoning"
            />
          )}
          {canViewDigitalSignage && (
            <SystemLink
              title={intl.formatMessage({ id: 'digitalSignage' })}
              icon={SignageIcon}
              path=""
            />
          )}
         {/*canViewZoning &&(
          
            <SystemLink
              title={intl.formatMessage({ id: 'zoning' })}
              icon={ZoneIcon}
              path="/zoning"
            />
         )*/}
                {/*canViewZoning &&(
         

         <a
         style={{
           backgroundColor: 'white',
           width: '30.5%',
           borderRadius: '35px',
           margin: '11px 14px',
           display: 'flex',
           flexDirection: 'column',
           alignItems: 'center',
           textDecoration: 'none',
           height:"148px"
         }}
         className='zoningSec'
         href="https://zone.mwasalaticard.com/"
         //href={`http://localhost:3001/?auth=${localStorage.getItem("authData")}`}
       >
         <img src={ZoneIcon} width="62px" alt="zone" style={{ display: 'block',marginTop:"28px" }} />
         <p style={{ textDecoration: 'none',textTransform:"uppercase", letterSpacing:"2.5px",marginTop: '12px',color:"#5a6577",fontSize:"15px",fontWeight:"550" }}>
           {intl.formatMessage({ id: 'zoning' })}
         </p>
       </a>
       
       
       )*/}
          {canViewHelpDesk && (
            <SystemLink
              title={intl.formatMessage({ id: 'helpDesk' })}
              icon={HelpIcon}
              path=""
            />
          )}
        </Grid>
      </div>
    </div>
  );
};

export { SuperDash };
